import { useState, useEffect } from "react";
import { PageProps } from "gatsby";
import StoryblokClient from "storyblok-js-client";

export const Storyblok = new StoryblokClient({
  accessToken: process.env.GATSBY_SB_GLOBAL,
  cache: {
    clear: "auto",
    type: "memory",
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

export function useStoryblok<T>(
  originalStory: StoryblokStory<T>,
  location: PropType<PageProps, "location">
): ParsedStoryblokStory<T> {
  const [story, setStory] = useState(getStory(originalStory));

  function getStory(originalStory: StoryblokStory<T>) {
    const story = originalStory;
    if (story && typeof story.content === "string") {
      story.content = JSON.parse(story.content);
    }

    return story as unknown as ParsedStoryblokStory<T>;
  }

  // adds the events for updating the visual editor
  // see https://www.storyblok.com/docs/guide/essentials/visual-editor#initializing-the-storyblok-js-bridge
  function initEventListeners() {
    const { StoryblokBridge } = window;
    if (typeof StoryblokBridge !== "undefined") {
      // initialize the bridge with your token
      const storyblokInstance = new StoryblokBridge({
        resolve_relations: [
          "common_post.author_teamMember",
          "common_post.post_author",
          "common_post.post_category",
          "data_project.project_partner",
          "data_teamMember.companies",
          "common_video.video_author",
          "common_video.video_author_team_member",
        ],
      });

      // reload on Next.js page on save or publish event in the Visual Editor
      storyblokInstance.on(["change", "published"], () =>
        window.location.reload()
      );

      // live update the story on input events
      storyblokInstance.on("input", (event: StoryblokEventPayload) => {
        // check if the ids of the event and the passed story match

        // change the story content through the setStory function
        //@ts-ignore
        setStory(event.story);
      });

      storyblokInstance.on("enterEditmode", (event: StoryblokEventPayload) => {
        // loading the draft version on initial enter of editor
        Storyblok.get(`cdn/stories/${event.storyId}`, {
          version: "draft",
          resolve_relations: [
            "common_post.author_teamMember",
            "common_post.post_author",
            "common_post.post_category",
            "data_project.project_partner",
            "data_teamMember.companies",
            "common_video.video_author",
            "common_video.video_author_team_member",
          ],
        })
          .then(({ data }) => {
            if (data.story) {
              setStory(data.story);
            }
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log("ERROR from Storyblok lib", error);
          });
      });

      storyblokInstance.pingEditor(() => {
        if (storyblokInstance.isInEditor()) {
          //
        } else {
          const params = new URLSearchParams(location.search);
          const storyId = params?.get("_storyblok");

          if (params && storyId) {
            // loading the draft version on initial enter of editor
            Storyblok.get(`cdn/stories/${storyId}`, {
              version: "draft",
              resolve_relations: [
                "common_post.author_teamMember",
                "common_post.post_author",
                "common_post.post_category",
                "data_project.project_partner",
                "data_teamMember.companies",
                "common_video.video_author",
                "common_video.video_author_team_member",
              ],
            })
              .then(({ data }) => {
                if (data.story) {
                  setStory(data.story);
                }
              })
              .catch((error) => {
                // eslint-disable-next-line no-console
                console.log("ERROR from Storyblok lib", error);
              });
          }
        }
      });
    }
  }

  function addBridge(callback: () => void) {
    // check if the script is already present
    const existingScript = document.getElementById("storyblokBridge");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "//app.storyblok.com/f/storyblok-v2-latest.js";
      script.id = "storyblokBridge";
      document.body.appendChild(script);
      script.onload = () => {
        // once the scrip is loaded, init the event listeners
        callback();
      };
    } else {
      callback();
    }
  }

  useEffect(() => {
    // only load inside preview mode
    if (location.search.includes("_storyblok")) {
      // eslint-disable-next-line no-console
      console.log(1, "RESET live preview");
      // first load the bridge, then initialize the event listeners
      addBridge(initEventListeners);
    }
  }, []); // runs the effect only once & defines effect dependencies

  return story;
}
